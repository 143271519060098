import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";

export interface ShopCreateState {
	stage: number;
}

export const state: ShopCreateState = {
	stage: 1
};

const namespaced: boolean = true;

export const getters: GetterTree<ShopCreateState, RootState> = {};

export const actions: ActionTree<ShopCreateState, RootState> = {
	async nextStage({ commit }) {
		commit("nextStage");
	},
	async resetStage({ commit }) {
		commit("resetStage");
	}
};

export const mutations: MutationTree<ShopCreateState> = {
	nextStage(state) {
		state.stage++;
	},
	resetStage(state) {
		state.stage = 1;
	}
};

export const shopCreate: Module<ShopCreateState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default shopCreate;
