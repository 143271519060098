import Vue from "vue";
// @ts-ignore
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

// @ts-ignore
export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: { base: "#212121", lighten1: "#555", lighten2: "#666" },
				secondary: "#111",
				accent: "#4A90E2",
				error: "#f44336",
				warning: "#FF9100",
				info: "#2196f3",
				success: "#4caf50",
				background: "#efefef"
			}
		}
	},
	icons: {
		iconfont: "md"
	}
});
