import { Commit } from "vuex";

export interface Paging {
	page: number;
	itemsPerPage: number;
	sortBy: string | Array<string>;
	totalItems?: number;
	descending: boolean;
	filters: { [key: string]: any };
}

export const pagingDefault: Paging = {
	page: 1,
	itemsPerPage: 25,
	sortBy: "",
	totalItems: 0,
	descending: false,
	filters: {} as { [key: string]: any }
};

export interface Status {
	success: boolean | string;
	error: boolean | string;
	loading: boolean;
}

export function statusFactory(): Status {
	return {
		success: false,
		error: false,
		loading: false
	};
}

export function successCommit(commit: Commit, payload: string) {
	commit("notifications/success", payload, { root: true });
}

export function errorCommit(commit: Commit, payload: string) {
	commit("notifications/success", payload, { root: true });
}
