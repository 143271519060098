import { utils } from "./http-common";
import Axios from "axios";
import { store } from "@/store";

export function importSafes() {
	import("@/store/modules/safes").then(({ safes }) => {
		if (!store.state.safes) store.registerModule("safes", safes);
		else return;
	});
}

export interface CITBody {
	id?: number;
	description: string;
	contactName: string;
	contactNumber: string;
	email: string;
}

export interface CashCentreBody {
	id?: number;
	description: string;
	contactName: string;
	contactNumber: string;
	email: string;
}

export interface BankAdjustmentBody {
	adjustmentAmount: number;
	adjustmentDescription: string;
	bankstatementStatus: number;
	adjustmentReasonId: number;
}

export class SafesApi {
	static async fetchBankStatementEntries(status: number, limit: number) {
		return Axios.get(utils.generateUrl(`api/admin/BankStatementEntry?status=${status}&limit=${limit}`, process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchBankStatementEntriesById(id: number) {
		return Axios.get(utils.generateUrl(`api/admin/BankStatementEntry/${id}?showrelated=true`, process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchClients() {
		return Axios.get(utils.generateUrl("api/admin/Client", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchClientTypes() {
		return Axios.get(utils.generateUrl("api/ClientType", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchRentalAmounts() {
		return Axios.get(utils.generateUrl("api/RentalAmount", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async createClientTypes(clientType: any) {
		return Axios.post(
			utils.generateUrl("api/ClientType", process.env.VUE_APP_SAFES_URL),
			{ ...clientType },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updateClientType(clientType: any) {
		return Axios.put(
			utils.generateUrl(`api/ClientType/${clientType.id}`, process.env.VUE_APP_SAFES_URL),
			{ description: clientType.description },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async fetchSafeOwners() {
		return Axios.get(utils.generateUrl("api/SafeOwner", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async createSafeOwners(safeOwner: any) {
		return Axios.post(
			utils.generateUrl("api/SafeOwner", process.env.VUE_APP_SAFES_URL),
			{ ...safeOwner },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updateSafeOwner(safeOwner: any) {
		return Axios.put(
			utils.generateUrl(`api/SafeOwner/${safeOwner.id}`, process.env.VUE_APP_SAFES_URL),
			{ description: safeOwner.description },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async fetchSafeSummary(clientId: number) {
		return Axios.get(utils.generateUrl(`api/admin/client/info/${clientId}`, process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchPossibleCloseBags() {
		return Axios.get(utils.generateUrl("api/admin/Bag/possibleclose", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async closeBag(id: number) {
		return Axios.put(utils.generateUrl(`api/admin/Bag/${id}/close`, process.env.VUE_APP_SAFES_URL), null, {
			headers: await utils.generateHeader()
		});
	}

	static async fetchBagsByClientId(clientid: number) {
		return Axios.get(utils.generateUrl("api/admin/Bag", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader(),
			params: { clientid }
		});
	}

	static async fetchEventsByBagId(id: number) {
		return Axios.get(utils.generateUrl(`api/admin/Bag/${id}/events`, process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchCashCentres() {
		return Axios.get(utils.generateUrl(`api/admin/CashCentre`, process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async updateCashCentre(centreObj: any) {
		return Axios.put(
			utils.generateUrl(`api/admin/CashCentre/${centreObj.id}`, process.env.VUE_APP_SAFES_URL),
			{ ...centreObj },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async fetchCITProviders() {
		return Axios.get(utils.generateUrl(`api/admin/CIT`, process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async updateClient(clientObject: any) {
		return Axios.put(
			utils.generateUrl(`api/admin/Client/${clientObject.id}`, process.env.VUE_APP_SAFES_URL),
			{ ...clientObject },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async createSafe(safeObject: any) {
		return Axios.post(
			utils.generateUrl(`api/admin/Client`, process.env.VUE_APP_SAFES_URL),
			{ ...safeObject },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async createCashCentre(cashcentreBody: CashCentreBody) {
		return Axios.post(utils.generateUrl(`api/admin/CashCentre`, process.env.VUE_APP_SAFES_URL), cashcentreBody, {
			headers: await utils.generateHeader()
		});
	}

	static async createCITProvider(citBody: CITBody) {
		return Axios.post(utils.generateUrl(`api/admin/CIT`, process.env.VUE_APP_SAFES_URL), citBody, {
			headers: await utils.generateHeader()
		});
	}

	static async updateCITProvider(citBody: CITBody) {
		return Axios.put(utils.generateUrl(`api/admin/CIT/${citBody.id}`, process.env.VUE_APP_SAFES_URL), citBody, {
			headers: await utils.generateHeader()
		});
	}

	static async createBankStatementEntry(id: number) {
		return Axios.post(utils.generateUrl(`api/admin/BankStatementEntry/${id}`, process.env.VUE_APP_SAFES_URL), null, {
			headers: await utils.generateHeader()
		});
	}

	static async updateBankStatementEntry(id: number, bankAdjustmentObject: BankAdjustmentBody) {
		return Axios.put(
			utils.generateUrl(`api/admin/BankStatementEntry/${id}`, process.env.VUE_APP_SAFES_URL),
			{ ...bankAdjustmentObject },
			{
				headers: await utils.generateHeader()
			}
		);
	}
}
