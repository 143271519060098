import { store } from "@/store";
import { utils } from "@/api/http-common";
import Axios from "axios";

export interface UserRepresentation {
	firstName?: string;
	lastName?: string;
	email?: string;
	attributes: {
		mobile?: Array<string>;
	};
}

export interface UserData {
	firstName?: string;
	lastName?: string;
	email?: string;
	mobile?: Array<string>;
}

export class UsersApi {
	static async getLoggedInUser() {
		return new Promise(resolve => {
			Axios.get(utils.generateUrl("users/logged_in"), {
				headers: utils.generateHeader()
			}).then(request => {
				store.commit("profile/profileLoaded", request.data.profile);
				resolve();
			});
		});
	}

	// Search user by fuzzy email query
	static async searchUsersByEmail(email: string) {
		return Axios.get(utils.generateKeycloakUrl("users"), {
			params: {
				email
			},
			headers: await utils.generateKeycloakHeader()
		});
	}

	//  Keycloak
	static async getUser(id: string) {
		return Axios.get(utils.generateKeycloakUrl(`users/${id}`), {
			headers: await utils.generateKeycloakHeader()
		});
	}
	static async updateUser(uid: string, userData: UserRepresentation) {
		return Axios.put(
			utils.generateKeycloakUrl(`users/${uid}`),
			{ ...userData },
			{
				headers: await utils.generateKeycloakHeader()
			}
		);
	}
	static async resetUserPassword(uid: string) {
		return Axios.put(utils.generateKeycloakUrl(`users/${uid}/execute-actions-email`), ["UPDATE_PASSWORD"], {
			headers: await utils.generateKeycloakHeader()
		});
	}

	static async fetchEmailActivity(query: string) {
		return Axios.get(utils.generateSendGridUrl(`messages?limit=100&query=${query}`), {
			headers: await utils.generateSendGridHeader()
		});
	}

	static async fetchEmailBounces(email: string) {
		return Axios.get(utils.generateSendGridUrl(`suppression/bounces/${email}`), {
			headers: await utils.generateSendGridHeader()
		});
	}

	static async getKYCUsers(paging: any) {
		return Axios.get(utils.generateUrl(`v2/users`), {
			params: {
				page_number: paging.page,
				page_size: paging.itemsPerPage,
				email: paging.email,
				id_verification_status: paging.verificationStatus
			},
			headers: await utils.generateHeader()
		});
	}

	static async updateKYCIdNumber(userId: string, data: any) {
		return Axios.put(
			utils.generateUrl(`v2/users/${userId}/id-number`),
			{ ...data },
			{
				headers: await utils.generateHeader()
			}
		);
	}
	static async updateKYCFileId(userId: string, fileID: File) {
		let formData = new FormData();
		formData.append("image", fileID);
		return Axios.put(utils.generateUrl(`v2/users/${userId}/id-image`), formData, {
			onUploadProgress: function(progressEvent) {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				store.commit("files/FILE_PERCENTAGE", percentCompleted);
			},
			headers: {
				...(await utils.generateHeader()),
				"Content-Type": "multipart/form-data"
			}
		});
	}
	static async updateKYCFileSelfie(userId: string, fileSelfie: File) {
		let formData = new FormData();
		formData.append("image", fileSelfie);
		return Axios.put(utils.generateUrl(`v2/users/${userId}/selfie-image`), formData, {
			onUploadProgress: function(progressEvent) {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				store.commit("files/FILE_PERCENTAGE_ALT", percentCompleted);
			},
			headers: {
				...(await utils.generateHeader())
			}
		});
	}

	static async getKYCUser(uid: string) {
		return Axios.get(utils.generateUrl(`v2/users/${uid}`), {
			headers: await utils.generateHeader()
		});
	}

	static async verifyKYCUser(data: any) {
		return Axios.put(
			utils.generateUrl(`v2/users/${data.userId}/evaluate-id`),
			{ approved: data.approved, reason: data.reason },
			{
				headers: await utils.generateHeader()
			}
		);
	}
}
