<template>
	<div id="app">
		<v-app id="inspire" v-if="isAuthenticated">
			<v-snackbar v-model="clientNotification" color="success" top right>{{ clientNotification }}</v-snackbar>
			<v-navigation-drawer class="secondary" dark fixed :clipped="$vuetify.breakpoint.mdAndUp" app v-model="drawer">
				<S2SNavigationMenus :config="menuConfig"></S2SNavigationMenus>
			</v-navigation-drawer>
			<toolbar v-on:onToggleButtonCLick="drawer = !drawer"></toolbar>
			<v-main :style="`background-color:${$vuetify.theme.themes.light.background}`">
				<div id="back-wrapper" :class="$vuetify.breakpoint.mdAndUp ? '' : 'dense'"><back-nav-btn :back="back"></back-nav-btn></div>
				<!-- Notifications is our global notifications component -->
				<notifications></notifications>
				<keep-alive include="SearchClient">
					<router-view></router-view>
				</keep-alive>
			</v-main>
		</v-app>
	</div>
</template>

<script>
import Vue from "vue";
import { SafesApi } from "@/api/safes-api";
import menuConfig from "./static/config/nav-menu-config.json";
import Toolbar from "@/components/Toolbar.vue";
import Notifications from "@/components/Notifications.vue";
import BackNavBtn from "@/components/BackNavBtn.vue";

export default Vue.extend({
	name: "App",

	components: {
		Toolbar,
		Notifications,
		BackNavBtn
	},

	data: function() {
		return {
			back: { name: null, params: null },
			drawer: null,
			menuConfig: menuConfig
		};
	},

	computed: {
		isAuthenticated: function() {
			return this.$store.getters["user/isAuthenticated"];
		},
		clientNotification: {
			get() {
				return this.$store.state.clientNotification;
			},
			set(val) {
				if (val === true) return;
				this.$store.dispatch("modules/safes/clearClientNotification");
			}
		}
	},

	created() {
		// Initial auth request to validate user
		this.$store.dispatch("user/authRequest");

		this.back = { name: this.$router.history.current.name, params: this.$router.history.current.params };
	}
});
</script>

<style lang="scss">
#back-wrapper {
	position: relative;
	width: 100%;
	text-align: right;

	> div {
		.v-btn {
			margin: 12px;
		}
	}
}
</style>
