<template>
	<div>
		<v-snackbar color="success" v-model="_success" top right
			>{{ status.success }}
			<v-btn color="primary" text @click="_success = false"><v-icon>close</v-icon></v-btn>
		</v-snackbar>
		<v-snackbar color="error" v-model="_error" top right>{{ status.error }}</v-snackbar>
	</div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "Notifications",

	computed: {
		status: function() {
			return this.$store.state.notifications.status;
		},
		_error: {
			get() {
				return this.status.error;
			},
			set(value) {
				if (value === true) return;
				this.$store.dispatch("notifications/clearStatus");
			}
		},
		_success: {
			get() {
				return this.status.success;
			},
			set(val) {
				if (val === true) return;
				this.$store.dispatch("notifications/clearStatus");
			}
		}
	}
});
</script>
