import { ActionTree } from "vuex";
import { UserState } from "@/store/user/types";
import keycloak from "keycloak-js";
import { RootState } from "@/store/types";
import { UsersApi, UserRepresentation } from "@/api/users-api";

export const actions: ActionTree<UserState, RootState> = {
	authRequest({ commit }) {
		const kc = keycloak({
			url: process.env.VUE_APP_AUTH_SERVER_URL!,
			realm: process.env.VUE_APP_REALM!,
			clientId: process.env.VUE_APP_RESOURCE!
		});

		const initOptions = {
			responseMode: <keycloak.KeycloakResponseMode>"fragment",
			flow: <keycloak.KeycloakFlow>"standard",
			onLoad: <keycloak.KeycloakOnLoad>"login-required",
			checkLoginIframe: false
		};

		const kcinit = () => {
			kc.init(initOptions)
				.success(async (authenticated: boolean) => {
					if (authenticated) {
						// Here we remove that ugly keycloak hash
						window.location.hash = "";
						window.history.pushState(null, "", window.location.href.replace("#", ""));
						kc.loadUserInfo().success(user => {
							commit("userLoaded", user);
						});
						commit("updateUserToken", kc.token);
						commit("setLogout", kc.logout);
						commit("setUpdateToken", updateToken);
					}
				})
				.error(function() {
					/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
					console.error("Init Error");
				});
		};
		kc.onAuthSuccess = function() {
			// Authenticated
		};

		kcinit();

		const updateToken = async () =>
			kc
				.updateToken(15)
				.success((refreshed: boolean) => {
					if (refreshed) {
						commit("updateUserToken", kc.token);
					}
				})
				.error(function() {
					kcinit();
				});
	},

	logout({ state }) {
		state.logout();
	},

	updateToken({ state }) {
		state.updateToken();
	},

	async searchUsersByEmail({ commit }, emailQuery: string) {
		try {
			commit("searchingUsers");
			const request = await UsersApi.searchUsersByEmail(emailQuery);
			commit("searchedUsers", request.data);
			commit("userSearchSuccess");
		} catch (error) {
			commit("userSearchError", error);
		}
	},

	async getUser({ commit, dispatch }, id: string) {
		try {
			commit("searchingUsers");
			const request = await UsersApi.getUser(id);
			commit("kcUserLoaded", request.data);
		} catch (error) {
			dispatch("notifications/error", `${error}`, { root: true });
		}
	},

	async updateUser({ commit, dispatch, rootState }, payload: { uid: string; userData: UserRepresentation }) {
		try {
			commit("searchingUsers");
			await UsersApi.updateUser(payload.uid, payload.userData);
			dispatch("organisations/users/fetchUsers", rootState.organisations.selectedOrganisation?.id, { root: true });
			dispatch("notifications/success", "User Updated!", { root: true });
		} catch (error) {
			dispatch("notifications/error", `${error}`, { root: true });
		}
	},

	async resetUserPassword({ commit, dispatch }, uid: string) {
		try {
			commit("searchingUsers");
			const request = await UsersApi.resetUserPassword(uid);
			dispatch("notifications/success", "Reset Password Instructions Sent!", { root: true });
		} catch (error) {
			dispatch("notifications/error", `${error}`, { root: true });
		}
	},

	async fetchEmailActivity({ commit, dispatch }, email: string) {
		try {
			commit("searchingUsers");
			let date = new Date();
			let today = date.toISOString();
			let lastWeekTimeStamp = date.setDate(date.getDate() - 7);
			let lastWeek = new Date(lastWeekTimeStamp).toISOString();
			let query = encodeURIComponent(`last_event_time BETWEEN TIMESTAMP "${lastWeek}" AND TIMESTAMP "${today}" AND to_email="${email}"`);
			const request = await UsersApi.fetchEmailActivity(query);
			commit("emailActivity", request.data.messages);
		} catch (error) {
			dispatch("notifications/error", `${error}`, { root: true });
		}
	},

	async fetchEmailBounces({ commit, dispatch }, email: string) {
		try {
			const request = await UsersApi.fetchEmailBounces(email);
			commit("emailBounces", request.data);
		} catch (error) {
			dispatch("notifications/error", `${error}`, { root: true });
		}
	},

	// KYC
	async getKYCUsers({ commit, dispatch }, paging) {
		try {
			commit("searchingUsers");
			const request = await UsersApi.getKYCUsers(paging);
			commit("kycUsers", request.data.results);
			commit("kycUsersTotal", request.data.count);
		} catch (error) {
			dispatch("notifications/error", `${error}`, { root: true });
		}
	},

	async updateKYCData({ dispatch }, data) {
		const uid = data.uid;

		// id object
		const idObj = {
			idNumber: data.kycData.idNum,
			idType: data.kycData.idType,
			gender: data.kycData.gender,
			dob: data.kycData.dob
		};

		const fileID = data.kycData.fileID;
		const fileSelfie = data.kycData.fileSelfie;

		const keycloakUser = {
			email: data.kycData.kycUser.email,
			firstName: data.kycData.kycUser.firstName,
			lastName: data.kycData.kycUser.lastName,
			attributes: {}
		};

		try {
			// update keycloak user here
			await UsersApi.updateUser(uid, keycloakUser);

			let IDImg = { status: 200 };
			let selfieImg = { status: 200 };

			const idRequest = await UsersApi.updateKYCIdNumber(uid, idObj);
			if (fileID) {
				const idFileRequest = await UsersApi.updateKYCFileId(uid, fileID);
				IDImg.status = idFileRequest.status;
			}
			if (fileSelfie) {
				const selfieFileRequest = await UsersApi.updateKYCFileSelfie(uid, fileSelfie);
				selfieImg.status = selfieFileRequest.status;
			}

			return {
				id: idRequest,
				files: {
					ID: IDImg,
					Selfie: selfieImg
				}
			};
		} catch (error) {
			dispatch("notifications/error", `Error: Invalid file format`, { root: true });
		}
	},

	async getKYCUser({ commit }, uid) {
		commit("searchingUsers");
		const request = await UsersApi.getKYCUser(uid);
		commit("kycUser", request.data);
	},

	async verifyKYC({ dispatch }, data) {
		try {
			const request = await UsersApi.verifyKYCUser(data);

			if (data.approved == true) {
				dispatch("notifications/success", `User ${data.userName} verified!`, { root: true });
			}
			return request;
		} catch (error) {
			dispatch("notifications/error", `${error}`, { root: true });
		}

		const request = await UsersApi.verifyKYCUser(data);
		return request;
	},

	async filterKycByEmail({ commit }, data) {
		commit("filterKycByEmail", data);
	},

	async filterVerificationStatus({ commit }, data) {
		commit("filterVerificationStatus", data);
	},

	clearSearchStatus({ commit }) {
		commit("clearSearchStatus");
	}
};
