import { MutationTree } from "vuex";
import { UserState, User } from "@/store/user/types";

export const mutations: MutationTree<UserState> = {
	updateUserToken(state, token) {
		state.error = false;
		state.token = token;
	},
	userLoaded(state, payload: User) {
		state.error = false;
		state.user = payload;
	},
	setLogout(state, payload) {
		state.logout = payload;
	},
	setUpdateToken(state, payload) {
		state.updateToken = payload;
	},
	userError(state) {
		state.error = true;
		state.user = undefined;
	},

	// Searched Users
	searchedUsers(state, payload) {
		state.searchedUsers = payload;
	},

	searchingUsers(state) {
		state.searchUserStatus.success = false;
		state.searchUserStatus.loading = true;
		state.searchUserStatus.error = false;
	},

	userSearchSuccess(state, payload?) {
		state.searchUserStatus.success = payload || true;
		state.searchUserStatus.loading = false;
		state.searchUserStatus.error = false;
	},

	userSearchError(state, payload?) {
		state.searchUserStatus.success = false;
		state.searchUserStatus.loading = false;
		state.searchUserStatus.error = payload || true;
	},

	// keycloak
	kcUserLoaded(state, payload) {
		state.error = false;
		state.kcUser = payload;
	},

	kycUsers(state, payload = []) {
		state.error = false;
		state.kycUsers = payload;
	},

	kycUsersTotal(state, payload = 0) {
		state.error = false;
		state.kycUsersTotal = payload;
	},

	kycUser(state, payload) {
		state.error = false;
		state.kycUser = payload;
	},

	filterKycByEmail(state, payload) {
		state.filterKycByEmail = payload;
	},

	filterVerificationStatus(state, payload) {
		state.verificationStatus = payload;
	},

	// SendGrid
	emailActivity(state, payload) {
		state.error = false;
		state.searchUserStatus.loading = false;
		state.emailActivity = payload;
	},

	emailBounces(state, payload) {
		state.error = false;
		state.searchUserStatus.loading = false;
		state.emailBounces = payload;
	}
};
