<template>
	<div>
		<v-btn color="accent" v-if="nav.length > 1" @click="backClicked"><v-icon>mdi-arrow-left</v-icon> back </v-btn>
	</div>
</template>

<script>
import Vue from "vue";
import { Route } from "vue-router";

export default Vue.extend({
	name: "BackNavBtn",

	props: {
		back: {
			type: Object,
			default: () => {}
		}
	},

	watch: {
		$route() {
			let inNav = false;
			for (let i = 0; i < this.$data.nav.length; i++) {
				if (this.$data.nav[i].name === this.$router.history.current.name) {
					inNav = true;
				}
			}

			if (inNav) {
				this.$data.nav.pop();
			} else {
				this.$data.nav.push({ name: this.$router.history.current.name, params: this.$router.history.current.params });
			}

			if (this.$data.nav.length == 2) {
				this.$data.prev = this.$data.nav[0];
			} else {
				this.$data.prev = this.$data.nav[this.$data.nav.length - 2];
			}
		}
	},

	data: function() {
		return {
			prev: { name: "home" },
			nav: []
		};
	},

	created: function() {
		this.$data.prev = this.$props.back;

		if (!this.$data.nav.length) {
			this.$data.nav.push({ name: "home" });
			if (this.$router.history.current.name !== "") {
				this.$data.nav.push({ name: this.$router.history.current.name, params: this.$router.history.current.params });
			}
		} else {
			if (!this.$props.back.name && this.$props.back.name !== "") {
				this.$data.nav.push({ name: this.$router.history.current.name, params: this.$router.history.current.params });
			} else {
				// push current path
				this.$data.nav.push(this.$props.back);
			}
		}
	},

	methods: {
		backClicked() {
			if (!this.$data.prev.name) {
				this.$router.push({ name: "home" });
			} else {
				this.$router.push(this.$data.prev);
			}
		}
	}
});
</script>

<style scoped>
.v-btn {
	position: relative;
	z-index: 1;
}
.v-btn .v-icon {
	margin-right: 10px;
}
</style>
