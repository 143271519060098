import { Module } from "vuex";
import { getters } from "@/store/user/getters";
import { actions } from "@/store/user/actions";
import { mutations } from "@/store/user/mutations";
import { UserState } from "@/store/user/types";
import { Utils } from "@/utils";
import { RootState } from "@/store/types";
import { statusFactory } from "../utils";

export const state: UserState = {
	token: "",
	loggedIn: false,
	logout: () => {},
	updateToken: () => {},
	error: false,
	searchUserStatus: statusFactory(),
	searchedUsers: [],
	user: undefined,
	kcUser: undefined,
	kycUsers: undefined,
	kycUsersTotal: undefined,
	kycUser: undefined,
	filterKycByEmail: undefined,
	verificationStatus: undefined,
	emailActivity: [],
	emailBounces: []
};

const namespaced: boolean = true;

export const user: Module<UserState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
