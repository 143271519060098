import { RootState } from "@/store/types";
import { ActionTree, MutationTree, GetterTree, Module } from "vuex";
import { Utils } from "@/utils";
import { statusFactory, Status } from "../utils";

/**
 * Actions
 */
export const actions: ActionTree<NotificationsState, RootState> = {
	async success({ commit }, message) {
		commit("success", message);
	},
	async error({ commit }, payload) {
		if (payload.message && payload.error) {
			const constructedMessage = `${payload.message}: ${payload.error.response.data.message || payload.error.response.data.error}`;
			commit("error", constructedMessage);
			Utils.logSentry(constructedMessage);
		} else if (payload.response) {
			commit("error", payload.response.data.message || payload.response.data.error || payload.response.data);
			Utils.logSentry(payload);
		} else {
			commit("error", payload);
		}
	},
	async clearStatus({ commit }) {
		commit("clearStatus");
	}
};

/**
 * Mutations
 */
export const mutations: MutationTree<NotificationsState> = {
	success(state, payload) {
		state.status.success = payload;
	},
	error(state, payload) {
		state.status.error = payload;
	},
	clearStatus(state) {
		state.status.error = false;
		state.status.success = false;
	}
};

/**
 * Getters
 */
export const getters: GetterTree<NotificationsState, RootState> = {};

/**
 * State
 */
export let state: NotificationsState = {
	status: statusFactory()
};

/**
 * Types
 */
export interface NotificationsState {
	status: Status;
}

const namespaced: boolean = true;

export const notifications: Module<NotificationsState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
