import { GetterTree } from "vuex";
import { UserState } from "@/store/user/types";
import { RootState } from "@/store/types";
import { UserRepresentation } from "@/api/users-api";

export const getters: GetterTree<UserState, RootState> = {
	token(state: UserState): string {
		return state.token;
	},
	isAuthenticated(state): boolean {
		return !!state.token;
	},

	fetchSelectedUser(state): UserRepresentation {
		let userData = {
			firstName: state.kcUser?.firstName,
			lastName: state.kcUser?.lastName,
			email: state.kcUser?.email,
			attributes: {
				mobile: [state.kcUser!.attributes!.mobile[0]]
			}
		};

		return userData;
	}
};
