import { store } from "@/store";

export namespace utils {
	export interface PagingBase {
		page?: number;
		pagesize?: number;
	}

	export function generateUrl(uri: string, hostUrlOverride?: string): string {
		if (hostUrlOverride) return hostUrlOverride + uri;
		return `${process.env.VUE_APP_API_HOST_URL}resource/` + uri;
	}

	export function generateKeycloakUrl(uri: string): string {
		return `${process.env.VUE_APP_AUTH_SERVER_URL}/admin/realms/${process.env.VUE_APP_REALM}/` + uri;
	}

	export async function generateHeader() {
		await store.state.user.updateToken();
		const myHeaders: any = {};

		const token = store.getters["user/token"];

		//check if the call is a marketplace connection
		//let client = store.getters["appConfig/clientId"];

		myHeaders["Authorization"] = "Bearer " + token;
		myHeaders["X-Ordercloud-Organisation"] = process.env.VUE_APP_CLIENT_ID;

		return myHeaders;
	}

	export async function generateKeycloakHeader() {
		await store.state.user.updateToken();
		const myHeaders: any = {};
		myHeaders["Authorization"] = "Bearer " + store.getters["user/token"];
		return myHeaders;
	}

	// SENDGRID
	export function generateSendGridUrl(uri: string): string {
		return process.env.VUE_APP_SG_API + uri;
	}

	export async function generateSendGridHeader() {
		const myHeaders: any = {};
		myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_SG_KEY}`;
		return myHeaders;
	}

	// SENDGRID
	export function generatePushyUrl(uri: string): string {
		return process.env.VUE_APP_PUSHY_API + uri;
	}

	export async function generatePushyHeader() {
		const myHeaders: any = {};
		return myHeaders;
	}
}
