import { RootState } from "@/store/types";
import { ActionTree, MutationTree, GetterTree, Module } from "vuex";
import { statusFactory, Status } from "../utils";
import { SafesApi } from "@/api/safes-api";

/**
 * Actions
 */
export const actions: ActionTree<SafesState, RootState> = {
	async fetchClients({ commit, dispatch }) {
		try {
			commit("loading");
			const response = await SafesApi.fetchClients();
			commit("setClients", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchClientTypes({ commit, dispatch }) {
		try {
			commit("loading");
			const response = await SafesApi.fetchClientTypes();
			commit("setClientTypes", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchRentalAmounts({ commit, dispatch }) {
		try {
			commit("loading");
			const response = await SafesApi.fetchRentalAmounts();
			commit("setRentalAmounts", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async createClientTypes({ commit, dispatch }, model: any) {
		try {
			commit("loading");
			await SafesApi.createClientTypes(model);
			dispatch("fetchClientTypes");
			dispatch("notifications/success", "Client Type created successfully", { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateClientType({ commit, dispatch }, model: any) {
		try {
			commit("loading");
			await SafesApi.updateClientType(model);
			dispatch("fetchClientTypes");
			dispatch("notifications/success", "Update successful", { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchSafeOwners({ commit, dispatch }) {
		try {
			commit("loading");
			const response = await SafesApi.fetchSafeOwners();
			commit("setSafeOwners", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async createSafeOwners({ commit, dispatch }, model: any) {
		try {
			commit("loading");
			await SafesApi.createSafeOwners(model);
			dispatch("fetchSafeOwners");
			dispatch("notifications/success", "Safe Owner created successfully", { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateSafeOwner({ commit, dispatch }, model: any) {
		try {
			commit("loading");
			await SafesApi.updateSafeOwner(model);
			dispatch("fetchSafeOwners");
			dispatch("notifications/success", "Update successful", { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchSafeSummary({ commit, dispatch }, clientId: number) {
		try {
			commit("loading");
			const response = await SafesApi.fetchSafeSummary(clientId);
			commit("setSummary", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchBagsByClientId({ commit }, clientId: number) {
		try {
			commit("bagsLoading");
			const response = await SafesApi.fetchBagsByClientId(clientId);
			commit("setClientBags", response.data);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchBankStatementEntries({ commit }, status: number = 0) {
		try {
			commit("bankStatementEntriesLoading");
			const response = await SafesApi.fetchBankStatementEntries(status, 500);
			commit("setBankStatementEntries", response.data);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchBankStatementEntriesById({ commit }, id: number) {
		try {
			commit("bankStatementEntriesLoading");
			const response = await SafesApi.fetchBankStatementEntriesById(id);
			commit("setBankStatementEntries", response.data);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchPossibleCloseBags({ commit }) {
		try {
			commit("bagsLoading");
			const response = await SafesApi.fetchPossibleCloseBags();
			commit("setClientBags", response.data);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async closeBag({ commit, dispatch }, id) {
		try {
			commit("bagsLoading");
			await SafesApi.closeBag(id);
			commit("notifications/success", `Bag ${id} has been closed`, { root: true });
			dispatch("fetchPossibleCloseBags");
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			commit("bagsSuccess");
		}
	},

	async fetchEventsByBagId({ commit, dispatch }, bagId: number) {
		try {
			commit("setBagEvents", []);
			commit("eventsLoading");

			const response = await SafesApi.fetchEventsByBagId(bagId);
			commit("setBagEvents", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchCashCentres({ commit, dispatch }) {
		try {
			commit("loading");
			const response = await SafesApi.fetchCashCentres();
			commit("setCashCentres", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchCITProviders({ commit, dispatch }) {
		try {
			commit("loading");
			const response = await SafesApi.fetchCITProviders();
			commit("setCITProviders", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateClient({ commit, dispatch }, clientObject: any) {
		try {
			commit("loading");
			await SafesApi.updateClient(clientObject);
			commit("updateClientSuccess");
			dispatch("notifications/success", "Client updated", { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async createSafe({ commit, dispatch }, safeObject: any) {
		try {
			commit("loading");
			await SafesApi.createSafe(safeObject);
			dispatch("notifications/success", `Safe ${safeObject.description} has been created`, { root: true });
			return true;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			return false;
		}
	},

	async createCashCentre({ commit, dispatch }, cashcentreBody) {
		try {
			commit("loading");
			const response = await SafesApi.createCashCentre(cashcentreBody);
			dispatch("fetchCashCentres");
			commit("notifications/success", `Cash Centre ${cashcentreBody.description} has been created`, { root: true });
			return response.status === 200;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateCashCentre({ commit, dispatch }, cashcentreBody) {
		try {
			commit("loading");
			const response = await SafesApi.updateCashCentre(cashcentreBody);
			dispatch("fetchCashCentres");
			commit("notifications/success", `Cash Centre "${cashcentreBody.description}" has been updated`, { root: true });
			return response.status === 200;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async createBankStatementEntry({ commit, dispatch }, id) {
		try {
			commit("loading");
			await SafesApi.createBankStatementEntry(id);
			commit("notifications/success", `Bank Statement for ${id} has been created`, { root: true });
			dispatch("fetchBankStatementEntries");
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateBankStatementEntry({ commit, dispatch }, obj: any) {
		try {
			commit("loading");
			await SafesApi.updateBankStatementEntry(obj.id, obj.bankAdjustmentObject);
			dispatch("notifications/success", `Bank Statement entry ${obj.id} has been adjusted`, { root: true });
			dispatch("fetchBankStatementEntries");
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async createCITProvider({ commit, dispatch }, citBody) {
		try {
			commit("loading");
			const response = await SafesApi.createCITProvider(citBody);
			dispatch("notifications/success", "New Cit Created: " + citBody.description, { root: true });
			dispatch("fetchCITProviders");
			return response.status === 200;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateCITProvider({ commit, dispatch }, citBody) {
		try {
			commit("loading");
			const response = await SafesApi.updateCITProvider(citBody);
			dispatch("notifications/success", "Cit Updated: " + citBody.description, { root: true });
			dispatch("fetchCITProviders");
			return response.status === 200;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async notifyClient({ commit }, clientNotifyJson: object) {
		commit("notifyClient", clientNotifyJson);
	},

	async clearClientNotification({ commit }) {
		commit("clearClientNotification");
	}
};

/**
 * Mutations
 */
export const mutations: MutationTree<SafesState> = {
	// Clients "state"
	setClients(state, payload: Client[]) {
		state.allClients = payload;
		state.status.loading = false;
	},
	setClientTypes(state, payload: any) {
		state.allClientTypes = payload;
		state.status.loading = false;
	},
	setRentalAmounts(state, payload: any) {
		state.rentalAmounts = payload;
		state.status.loading = false;
	},
	setSafeOwners(state, payload: any) {
		state.safeOwners = payload;
		state.status.loading = false;
	},
	updateClientSuccess(state) {
		state.status.loading = false;
	},
	setCashCentres(state, payload) {
		state.cashCentres = payload;
		state.status.loading = false;
	},
	setCITProviders(state, payload) {
		state.citProviders = payload;
		state.status.loading = false;
	},
	loading(state) {
		state.status.loading = true;
		state.status.error = false;
	},

	// Bags "state"
	setClientBags(state, payload: Bag[]) {
		state.bags = payload;
		state.bagsStatus.loading = false;
	},
	bagsSuccess(state) {
		state.bagsStatus.loading = false;
		state.bagsStatus.error = false;
	},
	bagsLoading(state) {
		state.bagsStatus.loading = true;
		state.bagsStatus.error = false;
	},
	bagsError(state) {
		state.bagsStatus.loading = false;
		state.bagsStatus.error = true;
	},

	// Events "state"
	setBagEvents(state, payload: BagEvent[]) {
		state.bagEvents = payload;
		state.eventsStatus.loading = false;
	},
	eventsLoading(state) {
		state.eventsStatus.loading = true;
		state.eventsStatus.error = false;
	},

	notifyClient(state, payload) {
		state.clientNotification = payload;
	},

	clearClientNotification(state) {
		state.clientNotification = false;
	},

	//Bank statement state
	setBankStatementEntries(state, payload: BankStatementEntry[]) {
		state.bankStatementEntries = payload;
		state.status.loading = false;
	},
	bankStatementEntriesLoading(state) {
		state.bankStatementEntryStatus.loading = true;
		state.bankStatementEntryStatus.error = false;
	},

	// Safe Summary
	setSummary(state, payload) {
		state.clientSafeSummary = payload;
		state.status.loading = false;
	}
};

/**
 * Getters
 */
export const getters: GetterTree<SafesState, RootState> = {
	getSummary(state) {
		return state.clientSafeSummary;
	},

	getClients(state) {
		const clients = state.allClients;
		return clients;
	}
};

/**
 * State
 */
export let state: SafesState = {
	status: statusFactory(),
	bagsStatus: statusFactory(),
	eventsStatus: statusFactory(),
	bankStatementEntryStatus: statusFactory(),
	allClients: [],
	allClientTypes: [],
	rentalAmounts: [],
	safeOwners: [],
	bags: [],
	bagEvents: [],
	cashCentres: [],
	citProviders: [],
	bankStatementEntries: [],
	clientSafeSummary: {} as SafeSummary,
	clientNotification: false
};

/**
 * Types
 */
export interface BankStatementEntry {
	id: number;
	account: string;
	num: number;
	statementDate: Date;
	seqNo: number;
	description: string;
	branchCode: string;
	amount: number;
	createdDate: Date;
	bankStatementStatus: number;
	bagNumberId: number;
	username: string;
	matchedDateTime: Date;
	origin: string;
	amountAdjustment: number;
	descriptionAdjustment: string;
	relatedId?: number;
}

export interface BagEvent {
	id: number;
	transactionid: string;
	beneficiarycode: string;
	citcode: string;
	deviceserial: string;
	transactiondate: Date;
	transactiontype: number;
	amount: number;
	status: number;
	collectionunit: string;
	vendorid: number;
	bagnumberid: number;
	paymentid: number;
	createdate: Date;
}

export interface Client {
	id: number;
	description: string;
	organisationid: number;
	safeserial: string;
	depositlimit: number;
	vendorid: number;
	active: number;
	safetype: number;
	vendordescription: string;
}

export interface Bag {
	id: number;
	bagserialnumber: string;
	clientid: number;
	amount: number;
	active: number;
	lastevent: Date;
	bankstatementid: number;
	vendorid: number;
	removalamount: number;
	removaldate: Date;
	events: BagEvent[];
	vendordescription: string;
}

export interface CashCentre {
	id: number;
	description: string;
}

export interface CITProvider {
	id: number;
	description: string;
}

export interface UnMatchedBankStatement {
	id: number;
	account: string;
	num: number;
	statementdate: string;
	seqno: number;
	description: string;
	branchcode: string;
	amount: number;
	createdate: string;
	bankstatementstatus: number;
	bagnumberid: number;
	username: string;
	matcheddatetime: string;
	origin: string;
	amountAdjustment: number;
	descriptionAdjustment: string;
	relatedId: number;
	adjustmentReasonId: number;
	amountWriteoff: number;
}

export interface BagSummary {
	id: number;
	bagserialnumber: string;
	amount: number;
	active: number;
	lastevent: string;
	removalamount: number;
	removaldate: string;
	removalSeal: string;
}

export interface Credits {
	id: number;
	transactionid: string;
	beneficiarycode: string;
	citcode: string;
	deviceserial: string;
	transactiondate: string;
	transactiontype: number;
	amount: number;
	status: number;
	collectionunit: string;
	vendorid: number;
	bagnumberid: number;
	paymentid: number;
	createdate: string;
}

export interface SafeSummary {
	unMatchedBankStatement: Array<UnMatchedBankStatement>;
	outStandingBags: Array<BagSummary>;
	bagInSafe: Array<BagSummary>;
	credits: Array<Credits>;
	lastRemoval: string;
	lastEvent: string;
}

export interface SafesState {
	allClients: Client[];
	allClientTypes: Array<any>;
	rentalAmounts: Array<any>;
	safeOwners: Array<any>;
	client?: Client;
	status: Status;

	bags: Bag[];
	bagsStatus: Status;

	cashCentres: CashCentre[];
	citProviders: CITProvider[];

	bagEvents: BagEvent[];
	eventsStatus: Status;

	bankStatementEntries: BankStatementEntry[];
	bankStatementEntryStatus: Status;

	clientSafeSummary: SafeSummary;

	clientNotification: boolean | object;
}

const namespaced: boolean = true;

export const safes: Module<SafesState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
