import Vue from "vue";
import Router, { Route } from "vue-router";
import { store } from "@/store";

const Accounting = () => import("@/views/metabase/Accounting.vue");
const Overview = () => import("@/views/metabase/Overview.vue");
const CreateSafe = () => import(/* webpackChunkName: "safe-create" */ "@/views/safes/CreateSafe.vue");
const CashCentresCreate = () => import(/* webpackChunkName: "cash-centres" */ "@/views/safes/CashCentresCreate.vue");
const CashCentresEdit = () => import(/* webpackChunkName: "cash-centres" */ "@/views/safes/CashCentresEdit.vue");
const CashCentres = () => import(/* webpackChunkName: "cash-centres" */ "@/views/safes/CashCentres.vue");
const CITProvidersCreate = () => import(/* webpackChunkName: "cit-providers" */ "@/views/safes/CITProvidersCreate.vue");
const CITProvidersEdit = () => import(/* webpackChunkName: "cit-providers" */ "@/views/safes/CITProvidersEdit.vue");
const CITProviders = () => import(/* webpackChunkName: "cit-providers" */ "@/views/safes/CITProviders.vue");
const ViewClients = () => import(/* webpackChunkName: "safe-clients" */ "@/views/safes/ViewClients.vue");
const ViewClientTypes = () => import(/* webpackChunkName: "safe-clients" */ "@/views/safes/ViewClientTypes.vue");
const ViewSafeOwners = () => import(/* webpackChunkName: "safe-clients" */ "@/views/safes/ViewSafeOwners.vue");
const ViewClientsEdit = () => import(/* webpackChunkName: "safe-clients" */ "@/views/safes/ViewClientsEdit.vue");
const ViewClientsSummary = () => import(/* webpackChunkName: "safe-clients" */ "@/views/safes/Summary.vue");
const SearchClient = () => import(/* webpackChunkName: "safe-clients" */ "@/views/safes/SearchClient.vue");
const BagEvents = () => import(/* webpackChunkName: "bag-events" */ "@/views/safes/BagEvents.vue");
const Shops = () => import("@/views/organisations/Shops.vue");
const OrganisationOverview = () => import(/* webpackChunkName: "organisations-overview" */ "@/views/organisations/OrganisationOverview.vue");

/** Recons uploading */
const UploadStatement = () => import("@/views/recons/UploadStatement.vue");
const UploadRecon = () => import("@/views/recons/UploadRecon.vue");
const DownloadRecon = () => import("@/views/recons/DownloadRecon.vue");
/** BankStatement */
const BankStatement = () => import(/* webpackChunkName: "bank-statement" */ "@/views/recons/BankStatement.vue");

/** Organisation Submodules */
const Profile = () => import(/* webpackChunkName: "organisations-profile" */ "@/views/organisations/Profile.vue");
const OrganisationStatements = () => import(/* webpackChunkName: "organisations-statements" */ "@/views/organisations/OrganisationStatements.vue");
const OrganisationDetails = () => import(/* webpackChunkName: "organisations-details" */ "@/views/organisations/OrganisationDetails.vue");
const ContactDetails = () => import(/* webpackChunkName: "organisations-contact-details" */ "@/views/organisations/ContactDetails.vue");
const Users = () => import(/* webpackChunkName: "organisations-users" */ "@/views/organisations/Users.vue");
const Addresses = () => import(/* webpackChunkName: "organisations-addresses" */ "@/views/organisations/Addresses.vue");
const AddressesCreate = () => import(/* webpackChunkName: "organisations-address-create" */ "@/views/organisations/AddressCreate.vue");
const Accounts = () => import(/* webpackChunkName: "organisations-accounts" */ "@/views/organisations/Accounts.vue");
const AccountCreate = () => import(/* webpackChunkName: "organisations-account-create" */ "@/views/organisations/AccountCreate.vue");
const Connections = () => import(/* webpackChunkName: "organisations-connections" */ "@/views/organisations/Connections.vue");
const ConnectionCreate = () => import(/* webpackChunkName: "organisations-connection-create" */ "@/views/organisations/ConnectionCreate.vue");
const ShopCreation = () => import(/* webpackChunkName: "organisation-create" */ "@/views/organisations/ShopCreation.vue");
const Settings = () => import(/* webpackChunkName: "organisations-settings" */ "@/views/organisations/settings/Settings.vue");
const OrganisationDevices = () => import(/* webpackChunkName: "organisations-devices" */ "@/views/organisations/OrganisationsDevice.vue");
const OrganisationDeviceHistory = () => import(/* webpackChunkName: "organisations-devices" */ "@/views/organisations/OrganisationsDeviceHistory.vue");
const OrganisationDeviceLinkHistory = () => import(/* webpackChunkName: "organisations-devices" */ "@/views/organisations/OrganisationsDeviceLinkHistory.vue");

const OrganisationActiveOrders = () => import(/* webpackChunkName: "organisations-active-orders" */ "@/views/organisations/OrganisationActiveOrders.vue");
const OrganisationSingleOrder = () => import(/* webpackChunkName: "organisations-order" */ "@/views/organisations/OrganisationSingleOrder.vue");
const OrganisationTransactionHistory = () =>
	import(/* webpackChunkName: "organisations-transaction-history" */ "@/views/organisations/OrganisationTransactionHistory.vue");

/** Devices **/
const SearchDevices = () => import(/* webpackChunkName: "devices" */ "@/views/devices/SearchDevice.vue");
const FailedTransactions = () => import(/* webpackChunkName: "devices" */ "@/views/devices/Transactions.vue");
const AppVersion = () => import(/* webpackChunkName: "devices" */ "@/views/devices/AppVersion.vue");
const SearchPan = () => import(/* webpackChunkName: "devices" */ "@/views/devices/SearchPan.vue");
const RejectedEvents = () => import(/* webpackChunkName: "devices" */ "@/views/devices/RejectedEvents.vue");

/** Airtime **/
const AirtimeOverview = () => import(/* webpackChunkName: "airtime" */ "@/views/airtime/AirtimeOverview.vue");
const AirtimeRouting = () => import(/* webpackChunkName: "airtime" */ "@/views/airtime/AirtimeRouting.vue");
const AirtimeUssdMenu = () => import(/* webpackChunkName: "airtime" */ "@/views/airtime/AirtimeUssdMenu.vue");
const AirtimeQRGeneration = () => import(/* webpackChunkName: "airtime" */ "@/views/airtime/AirtimeQRGeneration.vue");

const OrderWaybill = () => import(/* webpackChunkName: "organisations-order" */ "@/views/organisations/OrganisationOrderQRCode.vue");

/** Transactions **/
const Transactions = () => import(/* webpackChunkName: "transactions" */ "@/views/transactions/Transactions.vue");
const TransactionsAssign = () => import(/* webpackChunkName: "transactions" */ "@/views/transactions/TransactionsAssign.vue");

const EmailActivity = () => import(/* webpackChunkName: "email-activity" */ "@/views/EmailActivity.vue");

const Pushy = () => import(/* webpackChunkName: "email-activity" */ "@/views/Pushy.vue");

// KYC
const KycVerify = () => import(/* webpackChunkName: "kyc" */ "@/views/kyc/Verify.vue");
const KycUpdate = () => import(/* webpackChunkName: "kyc" */ "@/views/kyc/Update.vue");

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			name: "",
			component: Shops,
			beforeEnter: (to, from, next) => {
				importOrganisationState().then(() => {
					next();
				});
			}
		},
		{
			path: "/",
			name: "login"
		},
		{
			path: "/analytics/overview",
			name: "overview",
			component: Overview
		},
		{
			path: "/analytics/accounting",
			name: "accounting",
			component: Accounting
		},
		{
			path: "/home",
			name: "home",
			component: Shops,
			beforeEnter: (to, from, next) => {
				importOrganisationState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/clients",
			name: "safes-client",
			component: ViewClients,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/owners",
			name: "safes-owners",
			component: ViewSafeOwners,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/clients/types",
			name: "safes-client-types",
			component: ViewClientTypes,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/clients/:clientId/edit",
			name: "safes-client-edit",
			component: ViewClientsEdit,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					importOrganisationState().then(() => {
						next();
					});
				});
			}
		},
		{
			path: "/safes/clients/:clientId/safes-summary",
			name: "safes-summary-client",
			props: true,
			component: ViewClientsSummary,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/bags",
			name: "safes-client-bags",
			component: SearchClient,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/clients/:id",
			name: "safes-client-selected",
			component: SearchClient,
			props: true,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/close-possible-bags",
			name: "close-possible-bags",
			component: () => import(/* webpackChunkName: "close-possible-bag" */ "@/views/safes/ClosePossibleBags.vue"),
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/create",
			name: "create-safe",
			component: CreateSafe,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					importOrganisationState().then(() => {
						next();
					});
				});
			}
		},
		{
			path: "/safes/cash-centres/create",
			name: "create-cash-centre",
			component: CashCentresCreate,
			beforeEnter: async (to, from, next) => {
				await importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/cash-centres",
			name: "cash-centres",
			component: CashCentres,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/cash-centres/:centreId/edit",
			name: "edit-cash-centre",
			component: CashCentresEdit,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/cit-providers/create",
			name: "create-cit-provider",
			component: CITProvidersCreate,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/cit-providers/:providerId/edit",
			name: "edit-cit-provider",
			component: CITProvidersEdit,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/cit-providers",
			name: "cit-providers",
			component: CITProviders,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/safes/bag/:bagid/events",
			name: "bag-events",
			component: BagEvents,
			props: true,
			beforeEnter: (to, from, next) => {
				importSafesState().then(() => {
					next();
				});
			}
		},
		{
			path: "/organisations/shops",
			name: "organisations-shops",
			component: Shops,
			beforeEnter: (to, from, next) => {
				importOrganisationState().then(() => {
					next();
				});
			}
		},
		{
			path: "/organisations/shop-create",
			name: "shop-creation",
			component: ShopCreation,
			beforeEnter: async (to, from, next) => {
				await importOrganisationState();
				next();
			}
		},
		{
			path: "/organisations/:orgId",
			name: "organisations-overview",
			component: OrganisationOverview,
			beforeEnter: async (to, from, next) => {
				await importOrganisationState();

				next();
			},
			children: [
				{
					path: "/organisations/:orgId/profile",
					component: Profile,
					name: "organisation-profile",
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Profile");
						next();
					},
					meta: {
						breadCrumbs: {
							possiblePatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/details",
					name: "organisation-details",
					component: OrganisationDetails,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Shop Details");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/details",
					name: "organisation-contact-details",
					component: ContactDetails,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Contact Details");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/accounts",
					name: "organisation-accounts",
					component: Accounts,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Accounts");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/account/create",
					name: "organisation-account-create",
					component: AccountCreate,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Account Create");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [
								{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false },
								{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }
							],
							possiblePatterns: [{ to: { name: "organisation-accounts" }, text: "Accounts", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/addresses",
					name: "organisation-addresses",
					component: Addresses,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Addresses");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/address-edit/:addressId",
					name: "organisation-address-edit",
					component: AddressesCreate,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Address Edit");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [
								{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false },
								{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }
							],
							possiblePatterns: [{ to: { name: "organisation-addresses" }, text: "Addresses", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/address-create",
					name: "organisation-address-create",
					component: AddressesCreate,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Address Create");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [
								{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false },
								{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }
							],
							possiblePatterns: [{ to: { name: "organisation-addresses" }, text: "Addresses", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/users",
					name: "organisation-users",
					component: Users,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Users");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/connections",
					name: "organisation-connections",
					component: Connections,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Connections");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/connections/create",
					name: "organisation-connection-create",
					component: ConnectionCreate,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Connection Create");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [
								{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false },
								{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }
							],
							possiblePatterns: [{ to: { name: "organisation-connections" }, text: "Connections", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/connections/invites",
					name: "organisation-pending-connections",
					component: () => import(/* webpackChunkName: "pending-connections" */ "./views/organisations/PendingConnections.vue"),
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Pending Invites");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [
								{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false },
								{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }
							],
							possiblePatterns: [{ to: { name: "organisation-connections" }, text: "Connections", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/settings",
					name: "organisation-settings",
					component: Settings,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Settings");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/devices",
					name: "organisation-devices",
					component: OrganisationDevices,
					beforeEnter: async (to, from, next) => {
						await importDevicesState();
						buildBreadCrumbs(to, from, "Devices");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/devices/history",
					name: "organisation-device-history",
					component: OrganisationDeviceHistory,
					beforeEnter: async (to, from, next) => {
						await importDevicesState();
						buildBreadCrumbs(to, from, "History");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [
								{ to: { name: "organisation-profile" }, text: "Profile", disabled: false },
								{ to: { name: "organisation-devices" }, text: "Devices", disabled: false }
							]
						}
					}
				},
				{
					path: "/organisations/:orgId/devices/link-history",
					name: "organisation-device-link-history",
					component: OrganisationDeviceLinkHistory,
					beforeEnter: async (to, from, next) => {
						await importDevicesState();
						buildBreadCrumbs(to, from, "History");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [
								{ to: { name: "organisation-profile" }, text: "Profile", disabled: false },
								{ to: { name: "organisation-devices" }, text: "Devices", disabled: false }
							]
						}
					}
				},
				{
					path: "/organisations/:orgId/active-orders",
					name: "organisation-active-orders",
					component: OrganisationActiveOrders,
					props: true,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Active Orders");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/active-orders/:orderId",
					name: "organisation-order",
					component: OrganisationSingleOrder,
					props: true,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Active Orders > Order");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/active-orders/:orderId/waybill",
					name: "view-order-waybill",
					component: OrderWaybill,
					props: true,
					beforeEnter: (to, from, next) => {
						buildBreadCrumbs(to, from, "Order > Waybill");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/transaction-history",
					name: "organisation-transaction-history",
					component: OrganisationTransactionHistory,
					props: true,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Transaction History");
						await importTransactionReviewState();
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				},
				{
					path: "/organisations/:orgId/statements",
					name: "organisation-statements",
					component: OrganisationStatements,
					props: true,
					beforeEnter: async (to, from, next) => {
						buildBreadCrumbs(to, from, "Statements");
						next();
					},
					meta: {
						breadCrumbs: {
							prepentPatterns: [{ to: { name: "organisations-shops" }, text: "Organisations", disabled: false }],
							possiblePatterns: [{ to: { name: "organisation-profile" }, text: "Profile", disabled: false }]
						}
					}
				}
			]
		},

		{
			path: "/recons/upload-statement",
			name: "uploadStatement",
			component: UploadStatement
		},
		{
			path: "/recons/upload-recon",
			name: "uploadRecon",
			component: UploadRecon
		},
		{
			path: "/recons/download-recon",
			name: "downloadRecon",
			component: DownloadRecon
		},
		{
			path: "/recons/bank-statement",
			name: "bankStatement",
			component: BankStatement
		},
		{
			path: "/organisations/:orgId/settings",
			name: "settings",
			component: Settings,
			beforeEnter: async (to, from, next) => {
				await importOrganisationState();
				next();
			}
		},
		{
			path: "/devices/search",
			name: "searchDevices",
			component: SearchDevices,
			beforeEnter: async (to, from, next) => {
				await importDevicesState();
				next();
			},
			children: [
				{
					path: "/devices/search/transaction-history",
					name: "device-transaction-history",
					component: OrganisationDeviceHistory,
					beforeEnter: async (to, from, next) => {
						await importDevicesState();
						await importOrganisationState();
						buildBreadCrumbs(to, from, "Transaction History");
						next();
					},
					meta: {
						breadCrumbs: {
							possiblePatterns: [{ to: { name: "searchDevices" }, text: "Search Devices", disabled: false }]
						}
					}
				},
				{
					path: "/devices/link-history",
					name: "device-link-history",
					props: true,
					component: OrganisationDeviceLinkHistory,
					beforeEnter: async (to, from, next) => {
						await importDevicesState();
						await importOrganisationState();
						buildBreadCrumbs(to, from, "Link History");
						next();
					},
					meta: {
						breadCrumbs: {
							possiblePatterns: [{ to: { name: "searchDevices" }, text: "Search Devices", disabled: false }]
						}
					}
				}
			]
		},
		{
			path: "/devices/transactions",
			name: "failedTransactions",
			component: FailedTransactions,
			beforeEnter: async (to, from, next) => {
				await importDevicesState();
				next();
			}
		},
		{
			path: "/devices/appversion",
			name: "app-version",
			component: AppVersion,
			beforeEnter: async (to, from, next) => {
				await importDevicesState();
				next();
			}
		},
		{
			path: "/devices/pan",
			name: "search-pan",
			component: SearchPan,
			beforeEnter: async (to, from, next) => {
				await importDevicesState();
				next();
			}
		},
		{
			path: "/devices/rejected-events",
			name: "rejected-events",
			props: true,
			component: RejectedEvents,
			beforeEnter: async (to, from, next) => {
				await importDevicesState();
				next();
			}
		},
		{
			path: "/airtime",
			name: "airtime",
			component: AirtimeOverview,
			beforeEnter: async (to, from, next) => {
				await importAirtimeState();
				next();
			}
		},
		{
			path: "/airtime/routing",
			name: "airtime-routing",
			component: AirtimeRouting,
			beforeEnter: async (to, from, next) => {
				await importAirtimeState();
				next();
			}
		},
		{
			path: "/airtime/ussd",
			name: "airtime-ussd",
			component: AirtimeUssdMenu,
			beforeEnter: async (to, from, next) => {
				await importAirtimeState();
				next();
			}
		},
		{
			path: "/airtime/box-qr",
			name: "airtime-qr-generation",
			component: AirtimeQRGeneration,
			beforeEnter: async (to, from, next) => {
				await importAirtimeState();
				next();
			}
		},
		{
			path: "/transactions/review",
			name: "transactions-review",
			component: Transactions,
			beforeEnter: async (to, from, next) => {
				await importTransactionReviewState();
				next();
			}
		},
		{
			path: "/transactions/assign",
			name: "transactions-assign",
			component: TransactionsAssign,
			beforeEnter: async (to, from, next) => {
				await importTransactionReviewState();
				next();
			}
		},
		{
			path: "/email-activity",
			name: "email-activity",
			props: true,
			component: EmailActivity,
			beforeEnter: async (to, from, next) => {
				next();
			}
		},
		{
			path: "/pushy",
			name: "pushy",
			component: Pushy,
			beforeEnter: async (to, from, next) => {
				await importPushyState();
				next();
			}
		},
		{
			path: "/kyc",
			name: "verify-kyc",
			component: KycVerify,
			beforeEnter: (to, from, next) => {
				importOrganisationState().then(() => {
					importFilesState();
					next();
				});
			}
		},
		{
			path: "/kyc/update",
			name: "update-kyc",
			component: KycUpdate,
			props: true,
			beforeEnter: (to, from, next) => {
				importOrganisationState().then(() => {
					importFilesState();
					next();
				});
			}
		}
	]
});

/**
 * This is a very simple breadcrumps builder. It only supports one level deep for now.
 * This will most like evolve over time.
 *
 * In each route which you want to display a breadcrumb you need to add the following
 * under the meta attribute. Each possible pattern is the previous page's route information.
 * Possible pattern is an array because some pages may be accessed from different paths.
 *
 * breadCrumbs:
 * {
 *		possiblePatterns: [
 *			{ to: "/orders/active", text: "Active Orders", disabled: false },
 *			{ to: "/orders/all", text: "Order History", disabled: false }
 *		]
 *	}
 */
const buildBreadCrumbs = (to: Route, from: Route, text: string) => {
	if (!to.meta!.breadCrumbs) return;

	for (let pattern of to.meta!.breadCrumbs.possiblePatterns) {
		if (pattern.to.name === from.name) pattern.to.params = from.params; // Insure that the url parameters update
		to.meta!.breadCrumbs.pattern = [pattern, { to: { name: to.name, params: to.params }, text, disabled: true }];
		if (to.meta!.breadCrumbs.prepentPatterns) to.meta!.breadCrumbs.pattern.unshift(...to.meta!.breadCrumbs.prepentPatterns);
		break;
	}
};

/* States that will be lazy loaded */
const importOrganisationState = () =>
	import("@/store/organisations").then(({ organisations }) => {
		if (!store.state.organisations) store.registerModule("organisations", organisations);
		else return;
	});
const importSafesState = () =>
	import("@/store/modules/safes").then(({ safes }) => {
		if (!store.state.safes) store.registerModule("safes", safes);
		else return;
	});
const importDevicesState = () =>
	import("@/store/devices").then(({ devices }) => {
		if (!store.state.devices) store.registerModule("devices", devices);
		else return;
	});
const importAirtimeState = () =>
	import("@/store/airtime").then(({ airtime }) => {
		if (!store.state.airtime) store.registerModule("airtime", airtime);
		else return;
	});
const importTransactionReviewState = () =>
	import("@/store/transactions").then(({ transactions }) => {
		if (!store.state.transactions) store.registerModule("transactions", transactions);
		else return;
	});
const importPushyState = () =>
	import("@/store/pushy").then(({ pushy }) => {
		if (!store.state.pushy) store.registerModule("pushy", pushy);
		else return;
	});
const importFilesState = () =>
	import("@/store/files").then(({ files }) => {
		if (!store.state.files) store.registerModule("files", files);
		else return;
	});
