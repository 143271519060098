import Vue from "vue";
import Vuex, { Store } from "vuex";
import { user } from "@/store/user";
import { safes } from "@/store/modules/safes";
import { notifications } from "@/store/modules/notifications";
import { shopCreate } from "@/store/shopcreate";
import { RootState } from "./types";

Vue.use(Vuex);

/**
 * Plugin for vuex to watch for changes on active shop.
 * It will call all the refresh actions of all store if they have one
 */
const OnShopChangePlugin = (store: Store<RootState>) => {
	store.watch(
		state => (state.organisations ? state.organisations.selectedOrganisation : null),
		(val, oldVal) => {
			// Don't do anything on init state
			if (!oldVal) return;

			for (let state in store.state.organisations) {
				const action = `organisations/${state}/refresh`;
				// If the store does not have an refresh action ignore
				if ((store as any)._actions[action]) store.dispatch(action);
			}
		}
	);
};

export const store = new Vuex.Store({
	modules: {
		user,
		safes,
		notifications,
		shopCreate
	},
	plugins: [OnShopChangePlugin]
});
