import Vue from "vue";
import "./plugins/vuetify";
import router from "@/router";
import { store } from "@/store";
import VeeValidate from "vee-validate";
import vuetify from "./plugins/vuetify";
import Shop2ShopComponents from "shop2shop-vue-components";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import "leaflet/dist/leaflet.css";

import App from "./App.vue";

Vue.config.productionTip = false;
// Vue.config.performance = true;

// Initialise sentry
Sentry.init({
	dsn: `${process.env.VUE_APP_SENTRY_DSN}`,
	integrations: [new Integrations.Vue({ Vue, attachProps: true })],
	environment: `${process.env.VUE_APP_ENVIRONMENT}`,
	release: process.env.PACKAGE_VERSION,
	beforeSend(options: any) {
		options.user = store.state.user.user;
		return options;
	}
});

Vue.use(VeeValidate);
Vue.use(Shop2ShopComponents);

const app = new Vue({
	router,
	store,
	vuetify,
	render: (h: any) => h(App)
}).$mount("#app");

if ((<any>window).Cypress) {
	(<any>window).app = app;
}
