<template>
	<v-app-bar color="primary" dark app :clipped-left="$vuetify.breakpoint.mdAndUp">
		<v-app-bar-nav-icon cy-data="side-toggle-btn" @click.stop="$emit('onToggleButtonCLick')"></v-app-bar-nav-icon>
		<v-toolbar-title>Admin Dashboard</v-toolbar-title>

		<!-- TODO: move this layout code to its own component -->
		<v-spacer></v-spacer>
		<v-toolbar-items v-if="user">
			<v-menu offset-y left>
				<template v-slot:activator="{ on }">
					<v-btn text v-on="on">
						<span v-show="$vuetify.breakpoint.smAndUp">{{ user.email }}</span>
						<v-icon dark large>account_circle</v-icon>
					</v-btn>
				</template>

				<v-card>
					<v-list>
						<v-list-item>
							<v-list-item-avatar>
								<v-icon large>account_circle</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{ user.email }}</v-list-item-title>
								<v-list-item-subtitle>{{ user.name }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-divider></v-divider>
					<v-list class="py-0">
						<v-list-item @click="logout()">
							<v-list-item-title>Log Out</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-card>
			</v-menu>
		</v-toolbar-items>
	</v-app-bar>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "Toolbar",

	computed: {
		user: function() {
			return this.$store.state.user.user;
		}
	},

	methods: {
		logout() {
			this.$store.dispatch("user/logout");
		}
	}
});
</script>

<style scoped>
.v-menu .v-btn {
	height: 100%;
}
</style>
